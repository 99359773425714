<template>
  <div class="error-page-container">
    <h1>Oops! Your page is not found, Please check again later！</h1>
    <button @click="goHome">Return to the home page</button>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

function goHome() {
  router.push('/');
}
</script>

<style scoped>
.error-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 1100px;
  background-color: #f6f8f7;
  margin-top: 88px;
}

h1 {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 999px) {
  .error-page-container {
    width: 100vw;
    margin-top: 0px;
  }
}
</style>
